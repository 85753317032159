import { ProductSku } from 'api/types';
import DropDown from 'components/DropDown/DropDown';
import { WidgetProps } from 'components/types';
import useConfig from 'hooks/useConfig';
import { useState } from 'preact/hooks';
import LiveTestingIndicator from '../../v2/LiveTestingIndicator/LiveTestingIndicator';
import styles from './Widget.module.css';
import variables from '../../../theme/variables.module.css';
import ContaminantGroups from 'components/v4/ContaminantGroups/ContaminantGroups';
import ActiveIngredients from 'components/v4/ActiveIngredients/ActiveIngredients';
import ToolTip from 'components/ToolTip/ToolTip';
import CircleQuestion from 'components/Icons/CircleQuestion';
import DetailSideBar from 'components/v4/DetailSideBar/DetailSideBar';
import clsx from 'clsx';

const Widget = ({ product, style, hideActiveIngredients, hideContaminants, ...props }: WidgetProps) => {
  const { config } = useConfig();
  const { variant } = config;
  const { lastTestDate, skus = [] as ProductSku[] } = product;
  const [selectedSkuId, setSelectedSkuId] = useState<string | undefined>(skus?.[0]?.id);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);

  // Has a variant been defined by the page
  const hasVariant = variant && skus.some(({ shopifyVariantId }) => shopifyVariantId === variant);

  // Only look at variant data
  const sku =
    skus.find(({ id, shopifyVariantId }) => (hasVariant ? shopifyVariantId === variant : id === selectedSkuId)) ?? {};
  const { bigBars = [], contaminantGroups = [] } = sku;

  if (skus.length === 0) {
    // If we lack data to show, don't render anything
    return;
  }

  return (
    <div className={clsx(styles.widgetV4, variables.v4)} style={style} {...props}>
      <DetailSideBar
        sku={sku}
        lastTestDate={lastTestDate}
        isOpen={isSideBarOpen}
        onClose={() => setIsSideBarOpen(false)}
      />
      <div className={styles.widgetHeader}>
        Test Results Certified by 3rd Party Lab <LiveTestingIndicator lastTestDate={lastTestDate} />
      </div>
      <div className={styles.description}>
        This product was last tested for product quality assurance on April 3, 2024.
        <div>
          <div className={styles.detailLink} onClick={() => setIsSideBarOpen(true)}>
            View detailed results here.
          </div>
        </div>
      </div>
      {!hasVariant && (
        <DropDown
          onSelect={setSelectedSkuId}
          selectedId={selectedSkuId}
          options={skus.map(({ id, name }) => ({ id: id, label: name }))}
        />
      )}

      {!hideActiveIngredients && !!bigBars?.length && <ActiveIngredients bigBars={bigBars} />}
      {!hideContaminants && !!contaminantGroups?.length && <ContaminantGroups contaminantGroups={contaminantGroups} />}
      <div className={styles.ppb}>
        What is ppb?
        <ToolTip message="Parts per Billion" className={styles.tooltip}>
          <CircleQuestion size={12} />
        </ToolTip>
      </div>
    </div>
  );
};

export default Widget;
